<template>
  <span v-if="isReadOnly"
       v-p-tooltip.top="(isReadOnlyUser) ? 'Action not permitted for read-only users.' : `This ${saveType} is Read-Only`">
    <b-btn :class="btnClass" :disabled="true" variant="secondary">
      <fluency-icon v-if="icon" :type="iconToUse" :class="iconClassesToUse" />
      {{text}}
    </b-btn>
  </span>
  <span v-else
        class="position-relative"
        v-p-tooltip.top="{ value: tooltipMessage, disabled: !isSaveDisabled }">
    <b-btn :disabled="isSaveDisabled"
           :size="btnSize"
           :variant="variant"
           :class="[btnClass, 'fluency-save-button']"
           @click="buttonClick($event)">
      <fluency-icon v-if="icon" :type="iconToUse" :class="iconClassesToUse" />
      {{text}}
    </b-btn>
    <notification-badge v-if="showErrorBadge" :color="notificationType" style="margin-top:-0.35em;">!</notification-badge>
  </span>
</template>

<script>
import NotificationBadge from '../notificationBadge'
export default {
  name: 'fluencySaveButton',
  components: { NotificationBadge },
  props: {
    text: {
      type: String,
      default: 'Save'
    },
    icon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'save'
    },
    iconClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    tooltipMessage: {
      type: String,
      default: ''
    },
    btnSize: {
      type: String,
      default: 'md'
    },
    btnClass: {
      type: String,
      default: ''
    },
    saveType: {
      type: String,
      default: 'Item'
    },
    showErrorBadge: {
      type: Boolean,
      default: false
    },
    notificationType: {
      validator: function (value) {
        return ['danger', 'warning', 'info'].indexOf(value) !== -1
      },
      default: 'danger'
    },
    reverseSyncCheck: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    isReadOnly () {
      return this.readOnly || (this.user.roles || []).includes('read_only')
    },
    isReadOnlyUser () {
      return (this.user.roles || []).includes('read_only')
    },
    isSaveDisabled () {
      return this.disabled || this.isBackpackLoadingData || this.activeReverseSyncRunningOnAccount
    },
    isBackpackLoadingData () {
      if (this.$store.getters.loading) {
        return Object.values(this.$store.getters.loading).some(isLoading => isLoading)
      }
      return false
    },
    accountPlanId () {
      const settingsItem = this.$store.getters.settingsItem
      const activeItem = this.$store.getters.activeItem

      if (settingsItem) {
        return settingsItem?.accountPlanId
      }

      return activeItem?.accountPlanId
    },
    activeReverseSyncs () {
      return this.$store.getters.activeReverseSyncs
    },
    activeReverseSyncRunningOnAccount () {
      return this.activeReverseSyncs.includes(this.accountPlanId)
    },
    iconToUse () {
      if (this.activeReverseSyncRunningOnAccount) {
        return 'loop'
      }

      return this.iconType
    },
    iconClassesToUse () {
      if (this.activeReverseSyncRunningOnAccount) {
        return 'rotating' + this.iconClass
      }

      return this.iconClass
    }
  },
  methods: {
    emitClick (e) {
      this.$emit('click', e)
    },
    async buttonClick (e) {
      if (this.reverseSyncCheck && this.accountPlanId) {
        if (!this.activeReverseSyncRunningOnAccount) {
          await this.$store.dispatch('pollForReverseSync', { accountPlanId: this.accountPlanId })
        }

        if (this.activeReverseSyncRunningOnAccount) {
          this.$alert('There is a partner data sync in progress for this Account. Please try this action again in a few minutes.')

          return false
        }
      }

      this.emitClick(e)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
